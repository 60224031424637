import React from "react";
import { Link } from 'gatsby';

const Services6 = () => {
  return (
    
    <section className="serv-arch sectionp-padding">
      <div className="container-fluid">
      <div className="sec-head">
        < b/>
     
                <h6 className="full-width text-center wow fadeIn" data-wow-delay=".5s">
                
                  Portfolio
                </h6>
                <h3 className="full-width text-center wow color-font">
                  Recent Video Projects &amp; <br /> Other Demos.
                </h3>
              </div>
        <div className="row justify-content-center">
          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s2.jpg)" }}
          >
            <h6 className="numb">01</h6>
            <h5>   <Link to={"/project-details2/serco" } > Pilot Briefings</Link></h5>
            <h2>Serco - North America</h2>
            <p>
            Video, Earth Studio &amp; Real World Data
            </p>
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s1.jpg)" }}
          >
            <h6 className="numb">02</h6>
            <h5> <Link to={"/project-details2/atsap" } > ATSAP BRIEFINGS</Link></h5>
            <h2> <Link to={"/project-details2/atsap" } >Air Traffic Safety Action Program</Link></h2>
            <p>
            Video, Animations &amp; Social Media
            </p>
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s3.jpg)" }}
          >
            <h6 className="numb">03</h6>
            <h5> <Link to={"/project-details2/ansp" } > ANSP LEARNING</Link></h5>
            <h2> <Link to={"/project-details2/ansp" } >CANSO AND INFINA</Link></h2>
            <p>
              Replays, Chroma-Key, Multi-language.
            </p>
           
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s4.jpg)" }}
          >
            <h6 className="numb">04</h6>
          <h5> <Link to={"https://davidromeoleiva1ce5.myportfolio.com" } > PHOTOGRAPHY PORTFOLIO</Link></h5>
            <h2> <Link to={"https://davidromeoleiva1ce5.myportfolio.com" } >ADOBE PORTFOLIO</Link></h2>
            <p>
              Aperture and Composition.
            </p>
          
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s5.jpg)" }}
          >
            <h6 className="numb">05</h6>
            <h5> <Link to={"/project-details2/itadmin" } > IT ADMINISTRATION</Link></h5>
            <h2> <Link to={"/project-details2/itadmin" } >IN-HOUSE SOLUTIONS</Link></h2>
            <p>
              Firewalls, AV/IT Equipment &amp; Virtual Machines
            </p>
           
          </div>
          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s6.jpg)" }}
          >
            <h6 className="numb">06</h6>
            <h5> <Link to={"/project-details2/brasil" } > Amazonas Documentaries</Link></h5>
            <h2> <Link to={"/project-details2/brasil" } >CAMERAS IN THE JUNGLE</Link></h2>
            <p>
              Humidity, bugs &amp; lenses
            </p>
           
          </div>
        </div>
        <div className="row justify-content-center">
          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s7.jpg)" }}
          >
            <h6 className="numb">07</h6>
            <h5>   <Link to={"/project-details2/comingsoon" } > AFN Shared Services</Link></h5>
            <h2>Coming Soon</h2>
            <p>
            Video, Interviews &amp; Storytelling
            </p>
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s8.jpg)" }}
          >
            <h6 className="numb">08</h6>
            <h5> <Link to={"/project-details2/runway" } > Runway Safety Pilot Simulator</Link></h5>
            <h2> <Link to={"/project-details2/runway" } >Federal Aviation Adminstration</Link></h2>
            <p>
            Video, Flight Simulator &amp; Social Media
            </p>
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s9.jpg)" }}
          >
            <h6 className="numb">09</h6>
            <h5> <Link to={"/project-details2/livestreaming" } > Live Streaming</Link></h5>
            <h2> <Link to={"/project-details2/livestreaming" } >Let's Share Ideas!</Link></h2>
            <p>
              Switcher, Chroma-Key, Multi-language.
            </p>
           
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s10.jpg)" }}
          >
            <h6 className="numb">10</h6>
          <h5> <Link to={"/project-details2/comingsoon" } > Audio Mastering</Link></h5>
            <h2> <Link to={"/project-details2/comingsoon" } >Coming Soon</Link></h2>
            <p>
              Decibles, Dynamics and Signals.
            </p>
          
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s11.jpg)" }}
          >
            <h6 className="numb">11</h6>
            <h5> <Link to={"/project-details2/studio" } > STUDIO WORK</Link></h5>
            <h2> <Link to={"/project-details2/studio" } >Quiet on Set!</Link></h2>
            <p>
              Cameras, Switchers &amp; Chroma-Key
            </p>
           
          </div>
          <div
            className="col-lg col-md-6 item bg-img"
            style={{ backgroundImage: "url(/img/arch/s12.jpg)" }}
          >
            <h6 className="numb">12</h6>
            <h5> <Link to={"/project-details2/comingsoon" } > 3D Objects</Link></h5>
            <h2> <Link to={"/project-details2/comingsoon" } >Coming Soon</Link></h2>
            <p>
              Modeling, Scanning &amp; Textures
            </p>
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services6;
